import './SearchQuickLinks.scss';

import React from 'react';
import { Box, Typography } from '@mui/material';
import { FeatureToggle, SearchSettings } from "../../shared//models/app-data-model";
import GlobalLink from '../../shared/components/global-link/GlobalLink';


export type SearchQuickLinksProps = {
  featureToggles: FeatureToggle[];
  searchSettings: SearchSettings;  
};

const SearchQuickLinks: React.FC<SearchQuickLinksProps> = ({searchSettings}) => {
  const header = searchSettings?.quick_links_column[0]?.header;
  const linkGroup = searchSettings?.quick_links_column[0]?.link_group.map((group, index) =>
    //istanbul ignore next
    <GlobalLink url={group?.reference[0]?.url} key={index} label={group.title} />
  );

  return (
    <Box className='search-quick-links'>
      <Typography variant='h5'>{header}</Typography>
      {linkGroup}
    </Box>
  );
};

export default SearchQuickLinks;
