import React, { ReactNode } from 'react';
import App from '../app';

export type LayoutProps = {
  children: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
    return <App>{children}</App>
};

export default Layout;