import { IGatsbyImageData } from "gatsby-plugin-image";

import { AlertColor } from "@mui/material";

export type PhSiteSettings = {
  site_alert: SiteAlert[];
  feature_toggles: FeatureToggle[];
  search_settings: SearchSettings;
  speedbump_settings: SpeedbumpSettings;
  plan_details_app_connector?: string;
  login_dropdown_items: LoginDropdownItems;
  app_connector_api_data_source?: string;
};

export type SiteAlert = {
  title: string;
  alert_message: string;
  alert_type: AlertColor;
  button: Button;
};

export type FeatureToggle = {
  enabled: boolean;
  feature_title: string;
};

export type LoginDropdownItems = {
  login_item: LoginItem[];
};

export type LoginItem = {
  href: string;
  title: string;
};

// ATOMS - COMPONENTS
export type Reference = {
  contenthub_asset: any;
  manual_link?: any;
  short_description?: any;
  is_this_document_in_content_hub?: any;
  title?: string;
  url?: string;
  alt_text?: string;
  seo?: SEO;
  content?: string;
  answer?: string;
  uid?: string;
  _content_type_uid?: string;
  video_link?: string;
  script_source_url?: string;
  source_code?: string;
  markup?: string;
  enabled?: boolean;
  internal?: InternalType;
  link?: Link;
  modular_blocks?: SubNavModularBlock[];
};

export type SimpleReference = {
  href: string;
  type: 'ph_external_link' | 'ph_internal_link';
};

export type InternalType = {
  type: string;
}

export type Heading = {
  headline?: string;
  heading_type?: string;
};

export type MediaData = {
  add_a_class?: string[];
  contenthub_asset: ContentHubDamAssetData[];
  manual_overwrites: ManualOverwrites;
};

export type File = {
  url?: string;
  description?: string;
  title?: string;
}

export type RichText = {
  copy: string;
};

// MOLECULES - PATTERNS
export type Button = {
  label: string;
  button_type: string;
  reference?: Reference[];
  link?: Link[];
};

export type Action = {
  label: string;
  reference: Reference[];
};

export type Link = {
  title: string;
  url?: string;
  href?: string;
};

// ORGANISMS - FEATURES
export type SEO = {
  description?: string;
  contenthub_asset: ContentHubDamAssetData[];
  category_name?: string;
  category_url?: string;
  publish_date?: string;
  nav_title?: string;
  title?: string;
  created_at?: string;
  updated_at?: string;
  appleTouch?: string;
  authorUrl?: string;
  defaultAuthorUrl?: string;
  defaultLocale?: 'en_US',
  defaultPublisherUrl?: string;
  imageName?: string;
  defaultSiteImage?: string;
  facebookDomainVerification?: string;
  favicon16?: string;
  favicon32?: string;
  globalSchema?: string;
  noIndex?: string;
  keywords?: string | Array<string>;
  locale?: 'en_US',
  ogType?: 'website' | 'article',
  pagePath?: string;
  publisherUrl?: string;
  requireImage?: true,
  schema?: string;
  siteName?: string;
  siteUrl?: string;
  twitterCard?: 'summary_large_image',
  twitterCreator?: string;
  twitterSite?: string;
};

export type ManualOverwrites = {
  title: string;
  url: string;
  alt_text: string;
}

export type ContentHubDamAsset = {
  title: string;
  url: string;
  alt_text: string;
}

export type ContenthubAssetProperties = {
  Title: string;
  AltText: string;
}

export type ContentHubDamAssetData = {
  public_link: any;
  properties?: ContenthubAssetProperties;
}

export type ContentHubDamUrl = {
  href: string;
}

export type PageHeader = {
  headline: string;
  hero_copy: string;
  contenthub_asset: ContentHubDamAssetData[];
  primary_action: Action;
  secondary_button: Action;
};

export type ArticleGroupData = {
  variant: string;
  article_cards: ArticleCardData[];
};

export type ContentCardData = {
  button_label: string;
  button_url: string;
  title: string;
  date: string;
  reference: Reference[];
  variant?: string;
};

export type ArticleCardData = {
  title: string;
  button_label: string;
  url: string;
  reference: Reference[];
};

export type Accordion = {
  reference: Reference[];
  expanded: boolean;
};

export type AccordionGroupData = {
  accordions: Accordion[];
};

// TODO - User Path Group needs some revisiting in coming days
export type UserPathGroupData = {
  user_path_cards: UserPathCardData[];
  variant_choser: string;
};

export type UserPathCardData = {
  headline: string;
  short_description: string;
  reference: Reference[];
};

// ORGANISMS - MODULES
export type Content = {
  content_block: ContentBlockData[];
  content_header?: string;
};

export type ContentBlockData = {
  article_group?: ArticleGroupData;
  user_path_group?: UserPathGroupData;
  accordion_group?: AccordionGroupData;
  single_content_block?: SingleContentBlock;
  two_column_block?: TwoColumnBlock;
  compare_group?: CompareGroup;
  app_connector?: AppConnectorBlock;
  content_hero?: ContentHeroBlockData;
};

export type ContentHeroBlockData = {
  hero_title: string;
  content_hero: ContentHeroData[];
}

export type ContentHeroData = {
  title: string;
  background_image_dam: ContentHubDamAssetData[];
  background_color: string;
  background_type: string;
  content: ContentHeroContent[];
}

export type ContentHeroContent = {
  column: ContentHeroColumn;
}

export type ContentHeroColumn = {
  column_content: ContentHeroColumnContent[];
}

export type ContentHeroColumnContent = {
  rich_text?: RichText;
  button?: Button;
  image?: GlobalImageData;
  embeddable_content?: EmbeddableContentData;
};

export type GlobalImageData = {
  title: string
  contenthub_asset: ContentHubDamAssetData[];
  manual_overwrites: ManualOverwrites
  add_a_class?: string
}

export type AppConnectorBlock = {
  connect_to: ConnectTo[];
};

export type ConnectTo = {
  title: string;
  app_source_url: string;
  app_data: string;
  enabled: boolean;
};

export type CompareGroup = {
  content: CompareGroupContent;
};

export type CompareGroupContent = {
  title?: string;
  description?: string;
  included_grids: CompareGrid[];
};

export type CompareGrid = {
  compare_grid: CompareGridItem[];
};

export type CompareGridItem = {
  row: Row;
};

export type Row = {
  column: Column[];
};

export type LinkContent = {
  href?: string;
  title?: string;
};

export type ColorContent = {
  color?: string;
};


export type GradientContent = {
  end_color: string;
  gradient_direction: string;
  start_color: string;
};

export type ReferenceLinkContent = {
  contenthub_asset: any;
  manual_link: any;
  short_description: any;
  is_this_document_in_content_hub: any;
  title?: string;
  url?: string;
  alt_text?: string;
  seo: SEO;
  content?: string;
  answer?: string;
  uid?: string;
  _content_type_uid?: string;
  video_link?: string;
  script_source_url?: string;
  source_code?: string;
  markup?: string;
  enabled?: boolean;
  internal?: InternalType;
  link?: Link;
  modular_blocks?: SubNavModularBlock[];
};

export type Column = {
  value?: string;
  disclaimer_text?: string;
  value_tooltip_reference?: Reference[];
  title?: string;
};

export type TwoColumnBlock = {
  mobile_flip: boolean;
  container_style: string;
  first_column: ColumnItem[];
  second_column: ColumnItem[];
};

export type ColumnItem = {
  heading?: Heading;
  rich_text?: RichText;
  button?: Button;
  media?: MediaData;
  accordion_group?: AccordionGroupData;
  embeddable_content?: EmbeddableContentData;
  user_path_group?: UserPathGroupData;
  compare_group?: CompareGroup;
};

export type DocumentCardData = {
  button_label: string;
  button_url: string;
  short_description: string;
  title: string;
};

export type SingleContentBlock = {
  container_style?: string;
  single_column?: SingleColumn[];
};

export type SingleColumn = {
  button?: Button;
  embeddable_content?: EmbeddableContentData;
  heading?: Heading;
  media?: MediaData;
  rich_text?: RichText;
  user_path_group?: UserPathGroupData;
  compare_group?: CompareGroup;
  reference: Reference
};

export type AssetContent = {
  childImageSharp: ChildImageSharpContent;
};

export type ChildImageSharpContent = {
  gatsbyImageData: IGatsbyImageData;
  fluid: IGatsbyImageData;
};

export type FileContent = {
  content_type: string;
  localAsset?: AssetContent;
  title: string;
  url: string;
};

export type ImageContent = {
  file: FileContent;
  alt_text: string;
  title?: string;
};

export type EmbeddableContentData = {
  reference: Reference[];
  image_desktop?: ImageContent;
  image_mobile?: ImageContent;
}

export type PhMainNavigation = {
  title: string;
  main_navigation_group: MainNavbarLinks[];
};

export type MainNavbarLinks = {
  global_field: GlobalField;
};

export type GlobalField = {
  title?: string;
  label?: string;
  reference: Reference[];
};

export type SubNavigationMenusData = {
  title: string;
  main_navigation_items: MainNavigationItem[];
  right_nav: RightNavigation[];
};

export type MainNavigationItem = {
  title: string;
  reference: Reference[];
};

export type SubNavModularBlock = {
  quick_link_column?: QuickLinkColumnData;
  descriptive_link_group?: DescriptiveLinkGroupData;
}

export type QuickLinkColumnData = {
  group: MainNavigationItem[];
  description: string;
  header: string;
  highlight: boolean;
  header_link: HeaderLink[];
}

export type DescriptiveLinkGroupData = {
  descriptive_link_card: DescriptiveLinkCardData[];
}

export type DescriptiveLinkCardData = {
  page_title: string;
  page_short_description: string;
  reference: Reference[];
}

export type HeaderLink = {
  title: string;
  url: string;
}

export type RightNavigation = {
  button: Button;
};

// All filters pulling from core page.
export type AllPhCorepage = {
  edges: Edge[];
};

export type Edge = {
  node: CoreNode;
};

export type CoreNode = {
  audience_settings: AudienceSettings;
};

export type AudienceSettings = {
  audience_page_toggle: boolean;
  audience_title: string;
};

// TEMPLATES / PAGES
export type CorePageContent = {
  title: string;
  page_header: PageHeader;
  page_content: Content;
  core_subnav: SubNavigationMenusData[];
  page_alert: PageAlert[];
  section_footer: SectionFooterData[];
};

export type PageContent = {
  title: string;
  url: string;
  page_hierarchy?: PageHierarchy;
  page_header?: PageHeader;
  page_content?: Content;
  include_aside_on_this_page: boolean;
  aside_content?: AsideContentData;
  page_alert?: PageAlert[];
  updated_at: string;
};

export type PageHierarchy = {
  core_site: CoreSite[];
}

export type CoreSite = {
  core_subnav: SubNavigationMenusData[];
  section_footer: SectionFooterData[];
}

export type AsideContentData = {
  aside_navigations: AsideNavigationData[];
  aside_call_outs: AsideCallOut[];
};

export type AsideCallOut = {
  variant: string;
  headline: string;
  copy: string;
  button_group: Button[];
}

export type AsideNavigationData = {
  link_to: LinkTo[];
};

export type LinkTo = {
  link_list: LinkList[];
}

export type LinkList = {
  url: string;
  seo: SEO;
  title?: string;
  link?: Link
};

export type PlanDetailsContent = {
  title: string;
  url: string;
  page_hierarchy?: PageHierarchy;
  plan_short_description?: string;
  page_content?: Content;
  aside_content?: AsideContentData;
  page_alert?: PageAlert[];
  app_data?: string;
};

export type NewsReleaseContent = {
  title: string;
  url: string;
  page_content: Content;
  aside_content?: AsideContentData;
  seo?: SEO;
  category_chooser?: CategoryChooser[];
  related_articles?: RelatedArticleData[];
};

export type CategoryChooser = {
  title: string;
  url: string;
};

export type RelatedArticleData = {
  url: string;
  created_at: string;
  seo?: SEO;
};

export type NewsReleasesCard = {
  id: string;
  seo: SEO;
  created_at: string;
  updated_at: string;
  url: string;
};

export type NewsReleasesContent = {
  id?: string;
  title: string;
  url: string;
  seo?: SEO;
  category?: NewsReleasesByCategory[]
  created_at?: string;
  updated_at?: string;
  page_content: Content;
};

export type NewsReleasesByCategory = {
  newsCategoryId: string;
  newsReleaseCard: NewsReleaseCard;
};

export type NewsReleaseCard = {
  uid: string;
  seo: SEO;
  created_at: string;
  updated_at: string;
  url: string;
  description: string;
};

export type LandingPageContent = {
  title: string;
  include_aside_on_this_page: boolean;
  aside_content?: AsideContentData;
  page_alert: PageAlert[];
  page_content?: Content;
  page_header?: PageHeader;
};

// Global Context
export type AppDataModel = {
  main_navigation: PhMainNavigation;
  globalSettings: PhSiteSettings;
};

// Page Alerts
export type PageAlert = {
  alert_reference: AlertReference[];
};

export type AlertReference = {
  title: string;
  alert_message: string;
  alert_type: AlertColor;
  button: Button;
};

// Global Footer
export type PhGlobalFooterNav = {
  footer_rtf: string;
  social_media_links: SocialMediaLink[];
  footer_navigation_group: FooterNavigationGroup;
};

export type SocialMediaLink = {
  alt_text: string;
  svg_icon: SvgIcon;
  reference: Reference[];
};

export type SvgIcon = {
  height: string;
  width: string;
  contenthub_asset: ContentHubDamAssetData[];
};

export type Svg = {
  url: string;
};

export type FooterNavigationGroup = {
  quick_link_column: QuickLinkColumn[];
};

export type QuickLinkColumn = {
  header: string;
  description: string;
  reference: Reference[];
  link_group: LinkGroup[];
};

export type LinkGroup = {
  title: string;
  reference: Reference[];
};

export type BreadCrumb = {
  url: string;
  title: string;
};

// section footer
export type SectionFooterData = {
  title: string;
  footer_rtf?: string;
  footer_navigation_group?: FooterNavigationGroup;
};

// Manuals
export type PhManuals = {
  title: string;
  url?: string;
  page_hierarchy?: PageHierarchy;
  page_alert?: PageAlert[];
  top_user_path_group?: TopUserPathGroup[];
  link_card_group?: LinkCardGroup[];
};

export type TopUserPathGroup = {
  headline: string;
  short_description: string;
  reference: Reference[];
};

export type LinkCardGroup = {
  headline: string;
  short_description: string;
  reference: Reference[];
};

// SEARCH
export type SearchResultData = {
  search_result_type: SearchResultType;
  title: string;
  description: string;
  description_highlights: TextHighlight[];
  uri: string;
  score_confidence: ScoreConfidence;
  audience?: string;
  category?: string;
  link_title?: string;
}

export type SpellCorrectedQuery = {
  SuggestedQueryText: string;
  Corrections: Correction[];
}

export type Correction = {
  BeginOffset: number;
  EndOffset: number;
  Term: string;
  CorrectedTerm: string;
}

export type TextHighlight = {
  BeginOffset: number;
  EndOffset: number;
  TopAnswer: boolean;
  Type: HighlightType;
}

export type SearchSettings = {
  no_results_found_message: string;
  quick_links_column: SearchQuickLinkColumn[];
  search_filter_by_audience: SearchFilterByAudience[];
};

export type SpeedbumpSettings = {
  medicare_speedbump_content: string;
  whitelist: string[];
  external_speedbump_content: string;
};

export type SearchFilterByAudience = {
  display_title: string;
  audience_key: string;
}

export type SearchQuickLinkColumn = {
  header: string;
  link_group: LinkGroup[];
};

export enum HighlightType {
  Standard = "STANDARD",
  ThesaurusSynonym = "THESAURUS_SYNONYM"
}

export enum ScoreConfidence {
  Low = "LOW",
  Medium = "MEDIUM",
  High = "HIGH",
  VeryHigh = "VERY_HIGH",
  NotAvailable = "NOT_AVAILABLE"
}

export enum SearchResultType {
  Pages = "Pages",
  Documents = "Forms & Documents"
}

export type SearchFilters = {
  pagination?: Pagination,
  category: CategoryFilter
}

export type CategoryFilter = {
  isPages: boolean,
  isDocument: boolean
}

export type Pagination = {
  startIndex: number,
  endIndex: number
};
