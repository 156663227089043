import "./SearchNav.scss";

import React, {
  useCallback,
  useContext
} from "react";

import { Button } from "@mui/material";
import Grid2 from '@mui/material/Grid2';

import { UiStateContext } from "../../shared/contexts/ui-state/UiStateContext";
import { SearchFilterByAudience } from "../../shared/models/app-data-model";

export type SearchNavProps = {
  searchFilterByAudience: SearchFilterByAudience[];
};

const SearchNav: React.FC<SearchNavProps> = ({ searchFilterByAudience }: SearchNavProps) => {
  const uiStateContext = useContext(UiStateContext);

  const allAudiences: SearchFilterByAudience[] = [{
    display_title: "All",
    audience_key: "all"
  }];

  const combinedSearchFilterByAudience: SearchFilterByAudience[] = [...allAudiences, ...searchFilterByAudience];

  const handleActiveTab = useCallback((tab: SearchFilterByAudience): void => {
    uiStateContext.setSearchFilterByAudience?.(tab);
  }, [uiStateContext]);

  const navTabs = combinedSearchFilterByAudience.map((tab, index) => {
    const isActiveTab = uiStateContext.searchFilterByAudience && tab.audience_key === uiStateContext.searchFilterByAudience.audience_key;

    return <Button
      key={index}
      variant="text"
      data-testid={`nav-item-${index}`}
      className={`primary-nav-item search-nav-item ${isActiveTab ? 'open active-tab' : ''}`}
      onClick={() => handleActiveTab(tab)}
    >
      {tab.display_title}
    </Button >
  }
  );

  const desktop = (
    <Grid2 component="nav" className="search-nav-container">{navTabs}</Grid2>
  );

  return desktop;
};

export default SearchNav;
