import "./SearchResultCard.scss";

import React from "react";

import {
  PictureAsPdfRounded,
  Public
} from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardContent,
  Link,
  Typography
} from "@mui/material";

import TextWithHighlight from "../../shared/components/text-with-highlight/TextWithHighlight";
import {
  SearchResultData,
  SearchResultType
} from "../../shared/models/app-data-model";

export type SearchResultCardProps = {
  data: SearchResultData;
};

const SearchResultCard: React.FC<SearchResultCardProps> = ({ data }) => {
  let icon = (<Public className='icon' data-testid="page-icon" />);

  if (data.search_result_type === SearchResultType.Documents) {
    icon = (<PictureAsPdfRounded className='icon' data-testid="document-icon" />);
  }

  const content = (<CardActionArea>
    <CardContent className='content'>
      <Typography variant="h5" component="div" color='primary' className='result-type'>
        {icon}{data.search_result_type}
      </Typography>
      <Typography variant="h5" className='title'>
        {data.title}
      </Typography>
      {data.description && data.description.length > 0 && <TextWithHighlight className='description' text={data.description} highlights={data.description_highlights} />}
    </CardContent>
  </CardActionArea>);

    return <Card className='search-result-card'>
    {data.search_result_type === SearchResultType.Documents ? <Link href={data.uri} target="_blank" data-testid="document">
      {content}
    </Link> : <a href={data.uri} > {content} </a>}
  </Card>
};

export default SearchResultCard;
