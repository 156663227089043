import { BreadCrumb } from "../models/app-data-model";

export const kebabToStringTitle = (input: string): string =>
  input.replace(/[\-]/g, " ")
  .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

export const generateUrl = (url: string, index: number): string => {
    const urlArray = url.replace(/^\/+/, '').split('/');
    let finalUrl = "";
    for (let i = 0; i <= index; i++) {
        finalUrl += `/${urlArray[i]}`
    }
    return finalUrl;
};

export const getBreadCrumbData = (url: string): BreadCrumb[] => {
    const splitUrl = url.replace(/^\/+/, '').split('/');   
    const breadCrumbData = splitUrl.map((x, index) => {
        if (x) {
            return {
                title: kebabToStringTitle(x),
                url: generateUrl(url, index)
            }
        }
    });
    return breadCrumbData as BreadCrumb[];
};
