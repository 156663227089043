/* eslint-disable max-len */

/* istanbul ignore file */
import React, {
  ReactNode,
  useState
} from "react";

import { MainNavigationItem, SearchFilterByAudience } from "../../models/app-data-model";
import { UiStateContext } from "./UiStateContext";

export type AppDataContextProviderProps = {
  children: ReactNode;
};

/**
 * Providers our Global Ui State to all children
 *
 * @param {AppDataContextProviderProps} { children }
 * @returns
 */
const UiStateContextProvider: React.FC<AppDataContextProviderProps> = ({ children }: AppDataContextProviderProps) => {
  const [subNavDropdownOpen, setSubNavDropdownOpen] = useState(false);
  const [dropDownData, setDropDownData] = useState({} as MainNavigationItem);
  const [subNavOpenIndex, setSubNavOpenIndex] = useState(-1);
  const [searchQueryText, setSearchQueryText] = useState('');
  const [searchSuggestedQueryText, setSearchSuggestedQueryText] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [isSearchFilterPageOn, setIsSearchFilterPageOn] = useState(false);
  const [isSearchFilterDocumentOn, setIsSearchFilterDocumentOn] = useState(false);
  const [searchPage, setSearchPage] = useState(1);
  const [searchFilterByAudience, setSearchFilterByAudience] = useState({display_title: "All", audience_key: "all"} as SearchFilterByAudience);
  const [loginMenuOpen, setLoginMenuOpen] = useState(false);

  return (
    <UiStateContext.Provider
      value={{
        subNavDropdownOpen,
        setSubNavDropdownOpen,
        dropDownData,
        setDropDownData,
        subNavOpenIndex,
        setSubNavOpenIndex,
        searchQueryText,
        setSearchQueryText,
        searchSuggestedQueryText,
        setSearchSuggestedQueryText,
        searchOpen,
        setSearchOpen,
        isSearchFilterPageOn,
        setIsSearchFilterPageOn,
        isSearchFilterDocumentOn,
        setIsSearchFilterDocumentOn,
        searchPage,
        setSearchPage,
        searchFilterByAudience,
        setSearchFilterByAudience,
        loginMenuOpen,
        setLoginMenuOpen
      }}
    >
      {children}
    </UiStateContext.Provider>
  );
};

export default UiStateContextProvider;