import { DescriptionHighlight } from '../../models/app-data-model';
import './HighlightedText.scss';

import React from 'react';

export type HighlightedTextProps = {
  text: string;
  highlight: DescriptionHighlight;
};

const HighlightedText: React.FC<HighlightedTextProps> = ({ highlight, text }) => {
  return <strong className={highlight.TopAnswer ? "highlight top-answer" : "highlight"}>
    {text && text.substring(highlight.BeginOffset, highlight.EndOffset)}
  </strong>;
};

export default HighlightedText;
