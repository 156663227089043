import Contentstack from 'contentstack';
import { PhSiteSettings } from '../../models/app-data-model';

export type ContentTypes = 'site_settings';

const ContentStackApiService = {
    getContent(
        contentType: ContentTypes,
        entryUid?: string,
        referencedFields?: string[]
    ): Promise<PhSiteSettings> {
        if (!process.env.GATSBY_API_KEY || !process.env.GATSBY_DELIVERY_TOKEN || !process.env.GATSBY_ACTIVE_ENV || entryUid === undefined) {
            return new Promise(() => undefined);
        }

        const stack = Contentstack.Stack({ "api_key": process.env.GATSBY_API_KEY, "delivery_token": process.env.GATSBY_DELIVERY_TOKEN, "environment": process.env.GATSBY_ACTIVE_ENV });
        const query = stack.ContentType(contentType).Entry(entryUid);

        if (referencedFields) {
            query.includeReference(referencedFields);
        }

        return query.fetch().then(function success(entry) {
            return entry.toJSON();
        });
    }
};

export default ContentStackApiService;