import {
    ContentHubDamAsset,
    ContentHubDamAssetData,
    ContentHubDamUrl,
    ManualOverwrites
} from "../models/app-data-model";

export const getContentHubDamAsset = (asset: ContentHubDamAssetData[], overwrites?: ManualOverwrites): ContentHubDamAsset | undefined => {
    try {
        const contenthubDamAsset = {
            title: '',
            url: '',
            alt_text: '',
        } as ContentHubDamAsset;

        if (asset && asset.length > 0) {
            const publicLink = asset[0].public_link;
            const properties = asset[0].properties;

            /* istanbul ignore else */
            if (properties?.Title) {
                contenthubDamAsset.title = properties.Title;
            }

            /* istanbul ignore else */
            if (properties?.AltText) {
                contenthubDamAsset.alt_text = properties.AltText;
            }

            const publicLinkObject = Object.values(publicLink);

            /* istanbul ignore else */
            if (publicLinkObject && publicLinkObject.length > 0) {
                const url = publicLinkObject.flat()[0] as ContentHubDamUrl;

                contenthubDamAsset.url = overwrites && overwrites.url ? overwrites.url : url.href;
            }

            if (overwrites && overwrites.title) {
                contenthubDamAsset.title = overwrites.title;
            }

            if (overwrites && overwrites.alt_text) {
                contenthubDamAsset.alt_text = overwrites.alt_text;
            }

            return contenthubDamAsset;
        }

        return undefined;
    } catch {
        return undefined;
    }
};