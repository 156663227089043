import "./SearchResults.scss";

import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useState
} from "react";

import {
  Box,
  Link,
  Skeleton,
  Typography
} from "@mui/material";
import Grid2 from '@mui/material/Grid2';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { Media } from "../../shared/contexts/media/MediaContext";
import { UiStateContext } from "../../shared/contexts/ui-state/UiStateContext";
import useFeatureToggle from "../../shared/hooks/use-feature-toggle/useFeatureToggle";
import useKendraApi from "../../shared/hooks/use-kendra-api/useKendraApi";
import {
  FeatureToggle,
  SearchResultData,
  SearchResultType,
  SearchSettings
} from "../../shared/models/app-data-model";
import SearchFilterMobile from "../search-filter-mobile/SearchFilterMobile";
import SearchFilter from "../search-filter/SearchFilter";
import SearchNoResults from "../search-no-results/SearchNoResults";
import SearchResultCard from "../search-result-card/SearchResultCard";
import SearchSpellCorrection from "../search-spell-correction/SearchSpellCorrection";

export type SearchResultsProps = {
  featureToggles: FeatureToggle[];
  searchSettings: SearchSettings;
};

const SearchResults: React.FC<SearchResultsProps> = ({ featureToggles, searchSettings }) => {
  const isKendraSearchResultsEnabled = useFeatureToggle('search-results-from-kendra', featureToggles)
  const uiStateContext = useContext(UiStateContext);
  const [kendraApi] = useKendraApi();
  const [searchResultData, setSearchResultData] = useState(new Array<SearchResultData>());
  const [filteredResultData, setFilteredResultData] = useState(new Array<SearchResultData>());
  const [pageCount, setPageCount] = React.useState(0);
  const [filteredSearchCount, setFilteredSearchCount] = React.useState(0);


  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    uiStateContext.setSearchPage?.(value);
  };

  const searchTermChange = () => {
    uiStateContext.setSearchQueryText?.(uiStateContext.searchSuggestedQueryText);
    uiStateContext.setSearchSuggestedQueryText?.('');
  };
  
  useLayoutEffect(() => {
    /* istanbul ignore else */
    if (kendraApi?.response && kendraApi.response?.query_result) {
      uiStateContext.setSearchSuggestedQueryText?.('');
      setSearchResultData(kendraApi.response.query_result);
      if (kendraApi?.response && kendraApi.response?.spell_corrected_queries) {
        uiStateContext.setSearchSuggestedQueryText?.(kendraApi.response.spell_corrected_queries[0].SuggestedQueryText);
      }
    }
  }, [kendraApi, uiStateContext]);

  useEffect(() => {
    /* istanbul ignore else */
    if (searchResultData) {
      let finalFilteredData = searchResultData;
      let intermediateResult = searchResultData;
      let filteredByDocument = [] as SearchResultData[];
      let filteredByPage = [] as SearchResultData[];
      let filteredByAudience = [] as SearchResultData[];

      // Filter by Document Category
      if (uiStateContext.isSearchFilterDocumentOn) {
        filteredByDocument = searchResultData.filter((searchResultCard) => {
          return searchResultCard.search_result_type === SearchResultType.Documents;
        });
      }

      // Filter by Page Category
      if (uiStateContext.isSearchFilterPageOn) {
        filteredByPage = searchResultData.filter((searchResultCard) => {
          return searchResultCard.search_result_type === SearchResultType.Pages;
        });
      }
      
      if (uiStateContext.isSearchFilterPageOn || uiStateContext.isSearchFilterDocumentOn) {
        intermediateResult = [...filteredByDocument, ...filteredByPage];
      }
      else
      {
        intermediateResult = searchResultData;
      }

      if (uiStateContext.searchFilterByAudience && uiStateContext.searchFilterByAudience.audience_key !== "all") {
        filteredByAudience = intermediateResult.filter((searchResultCard) => {
          return searchResultCard.audience?.toLocaleLowerCase() === uiStateContext.searchFilterByAudience.audience_key;
        });
      } else {
        filteredByAudience = [];
      }

      if (uiStateContext.searchFilterByAudience && uiStateContext.searchFilterByAudience.audience_key !== "all") {
        finalFilteredData = [...filteredByAudience];
      }
      else if (uiStateContext.isSearchFilterPageOn || uiStateContext.isSearchFilterDocumentOn) {
        finalFilteredData = [...filteredByDocument, ...filteredByPage];
      }
      else
      {
        finalFilteredData = [...searchResultData];
      }

      // Page Count Calcuation AFTER Filters are applied
      const pageCount = finalFilteredData.length > 0 ? Math.ceil(finalFilteredData.length / 10) : 0;
      setPageCount(pageCount);
      setFilteredSearchCount(finalFilteredData.length);

      // Page Change
      const startIndex = (uiStateContext.searchPage - 1) * 10;
      const endIndex = startIndex + 10;
      setFilteredResultData(finalFilteredData.slice(startIndex, endIndex));

    }

  }, [uiStateContext.isSearchFilterDocumentOn, uiStateContext.isSearchFilterPageOn, uiStateContext.searchPage, searchResultData, uiStateContext.searchFilterByAudience]);

  const filteredNoResultsMessage = (<Typography component="p">{searchResultData.length} {searchResultData.length == 1 ? 'result' : 'results'} filtered</Typography>);
  const filteredResultMessage = (<Typography component="p">{filteredSearchCount} {filteredSearchCount === 1 ? 'result' : 'results'} shown</Typography>);
  const spellCorrectedMessage = (<Typography component="p">Did you mean "<Link className="suggested-link" data-testid='suggested-text' onClick={searchTermChange}>{uiStateContext.searchSuggestedQueryText}</Link>"?</Typography>)
  const searchResultsCount = (<Typography component="p">{searchResultData.length} {searchResultData.length === 1 ? 'result' : 'results'} for <strong>"{kendraApi.response.query}"</strong></Typography>);
  const searchSuggestion = uiStateContext.searchSuggestedQueryText?.length > 0 ? (<div className='search-suggestion-text'><Typography component="p">{uiStateContext.searchSuggestedQueryText && spellCorrectedMessage}</Typography></div>) : "";
  const searchResultsCards = filteredResultData.length > 0 ? filteredResultData.map((searchResultCard, index) => <SearchResultCard data={searchResultCard} key={index} />) : <SearchNoResults searchSettings={searchSettings} />;
  const skeletonLoader = (<Box className='skeleton-loader-container'>
    <Skeleton animation="wave" variant="rounded" height="1.5rem" width="20%" className='skeleton-loader' />
    {[1, 2, 3, 4].map((index) => <Skeleton animation="wave" variant="rounded" height="8rem" className='skeleton-loader' key={index} />)}
  </Box>);

  const searchResultsContainer = (<>
    <Media lessThan='md' className="mobile">
      <SearchFilterMobile></SearchFilterMobile>
    </Media>
    <Grid2 container className="search-results-container">
      <Grid2 container size={{ xs: 12, md: 4, lg:3 }} component='aside' className='search-filter-container'>
        <Media greaterThanOrEqual="md" className='desktop'>
          <SearchFilter></SearchFilter>
        </Media>
      </Grid2>
      <Grid2 container component='main' className='search-results'>
        <div className='search-results'>
          {searchSuggestion}
          {(uiStateContext.isSearchFilterDocumentOn || uiStateContext.isSearchFilterPageOn || uiStateContext.searchFilterByAudience && uiStateContext.searchFilterByAudience.audience_key !== "all") && filteredSearchCount > 0 && filteredResultMessage}
              {searchResultsCount}
              {(uiStateContext.isSearchFilterDocumentOn || uiStateContext.isSearchFilterPageOn) && filteredSearchCount == 0 && filteredNoResultsMessage}
              {searchResultsCards}
              {(filteredResultData.length > 0 && pageCount > 1) && <Stack spacing={2}><Pagination shape="rounded" color="primary" count={pageCount} page={uiStateContext.searchPage} onChange={handlePageChange} data-testid="pagination" /></Stack>}
        </div>
      </Grid2>
    </Grid2></>);

  return <>
    {
      isKendraSearchResultsEnabled && (uiStateContext.searchQueryText?.length > 2) && (
        kendraApi.isRunning ? skeletonLoader : (
          kendraApi.hasError ? <h4 className="search-result-states">Sorry we are unable to search right now.</h4> :
            (
              searchResultData.length > 0 ? searchResultsContainer : 
              (kendraApi.response.spell_corrected_queries ? <SearchSpellCorrection searchSettings={searchSettings} />: <SearchNoResults searchSettings={searchSettings} /> )
            )
        )
      )
    }
  </>;
};

export default SearchResults;
