import './GlobalLink.scss';
import React from 'react';

export type GlobalLinkProps = {
  className?: string;
  label: string
  url?: string
};

const GlobalLink: React.FC<GlobalLinkProps> = ({ className, label, url }: GlobalLinkProps) => {
  return <div><a href={url} className={className ? `button ${className}` : 'link'} >{label}</a></div>;
};

export default GlobalLink;
