import { useContext } from 'react';
import { FeatureToggle } from '../../models/app-data-model';
import { AppDataContext } from '../../contexts/app-data/AppDataContext';

/**
 * A hook for wrapping a feature around a toggle
 * It will check to see if your feature toggle exists and if it is enabled
 * If it does not exist, it will assume the feature is disabled.
 * *NOTE* This means that when we retire feature toggles, we need to remove them from code **first**.
 */
const useFeatureToggle = (featureName: string, staticToggles: FeatureToggle[]): boolean => {
    const appDataContext = useContext(AppDataContext);
    const staticFeatureToggle = staticToggles && staticToggles.length > 0 && staticToggles?.find((feature) => feature.feature_title === featureName)?.enabled as boolean;
    const runtimeFeatureToggle = appDataContext?.appData?.globalSettings?.feature_toggles?.find(
        (feature) => feature.feature_title === featureName);
    
    return runtimeFeatureToggle ? runtimeFeatureToggle.enabled : staticFeatureToggle;
};

export default useFeatureToggle;
