import {
  useCallback,
  useContext,
  useLayoutEffect,
  useState
} from "react";

import { UiStateContext } from "../../contexts/ui-state/UiStateContext";
import {
  SearchResultData,
  SpellCorrectedQuery
} from "../../models/app-data-model";
import KendraApiService from "../../services/kendra-api/kendra-api-service";

// eslint-disable-next-line
export type KendraApiResponse = {
  query: string;
  query_result: Array<SearchResultData>;
  total_results: number;
  spell_corrected_queries: Array<SpellCorrectedQuery> | null;
}

export type ApiServiceStatus = {
  response: KendraApiResponse;
  isRunning: boolean;
  hasError: boolean;
  error?: any;
};

const useKendraApi = (): [ApiServiceStatus, () => Promise<void>] => {
  const [isRunning, setIsRunning] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(undefined as any);
  const [response, setResponse] = useState({} as KendraApiResponse);
  const [apiStatus, setApiStatus] = useState({ isRunning: false, response: response } as ApiServiceStatus);
  const uiStateContext = useContext(UiStateContext);

  useLayoutEffect(() => {
    if (uiStateContext.searchQueryText?.length > 2) {
      apiCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiStateContext.searchQueryText]);

  useLayoutEffect(() => {
    setApiStatus({ isRunning: isRunning, hasError: hasError, response: response, error: error });
  }, [isRunning, hasError, response, error]);

  const apiCall = useCallback(async () => {
    setIsRunning(true);
    setHasError(false);
    try {
      const content = await KendraApiService.getSearchResult(
        uiStateContext.searchQueryText
      );
      setHasError(false);
      setIsRunning(false);
      setResponse(content);
    } catch (err) {
      setResponse({} as KendraApiResponse);
      setError(err);
      setHasError(true);
      setIsRunning(false);
    }
  }, [uiStateContext.searchQueryText]);

  return [apiStatus, apiCall];
};

export default useKendraApi;
