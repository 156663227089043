import React from 'react';
import parse, { domToReact } from 'html-react-parser';

import './RichTextBlock.scss';
import { sanitizeRichText } from '../../services/rich-text-sanitizer';
import PhLink from '../ph-link/ph-link';

export type RichTextBlockProps = {
  content: string;
};

const RichTextBlock: React.FC<RichTextBlockProps> = ({ content }) => {
  const options = {
    replace: (domNode) => {
      if (domNode.name === 'a' && domNode.attribs.href) {
        const href = domNode.attribs.href;
        const ignoreList = [
          '#',
          'tel:',
          'sms:',
          'mailto:',
        ];
        if (ignoreList.every((e) => !href.startsWith(e))) {
          const children = domToReact(domNode.children);

          return <PhLink href={href} children={children}></PhLink>;
        }
      }
    }
  };

  const parsedContent = parse(sanitizeRichText(content), options);

  return (
    <div className='rich-text-block'>{ parsedContent }</div>
  );
};

export default RichTextBlock;
