import "./SearchBar.scss";

import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

import { UiStateContext } from "design-system/shared/contexts/ui-state/UiStateContext";

import {
  Close,
  Search as SearchIcon
} from "@mui/icons-material";
import {
  Box,
  InputBase
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

const SearchBar: React.FC = () => {
  const uiStateContext = useContext(UiStateContext);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchClose = useCallback((): void => {
    uiStateContext.setSearchOpen?.(false);
    uiStateContext.setSearchQueryText?.('');
    uiStateContext.setSearchSuggestedQueryText?.('');
    uiStateContext.setIsSearchFilterPageOn?.(false);
    uiStateContext.setIsSearchFilterDocumentOn?.(false);
    uiStateContext.setSearchFilterByAudience?.({ display_title: "All", audience_key: "all" });
  }, [uiStateContext]);

  useEffect(() => {
    if (searchTerm?.length > 2) {
      const delayDebounceFn = setTimeout(() => {
        uiStateContext.setSearchQueryText?.(searchTerm);
      }, 500);

      /* istanbul ignore next */
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm, uiStateContext, uiStateContext.setSearchQueryText]);

  useEffect(() => {
    setSearchTerm(uiStateContext.searchQueryText);
  }, [uiStateContext.searchQueryText]);

  return (
    <>
      <Box className="search-bar">
        <SearchIcon />
        <InputBase className="search-input-textfield"
          autoFocus
          placeholder="Search"
          inputProps={{ 'aria-label': 'search' }}
          value={searchTerm}
          onChange={(event) => [setSearchTerm(event.target.value),
            uiStateContext.setSearchSuggestedQueryText?.(''),
            uiStateContext.setIsSearchFilterPageOn?.(false),
            uiStateContext.setIsSearchFilterDocumentOn?.(false),
            uiStateContext.setSearchPage?.(1),
            uiStateContext.setSearchFilterByAudience?.({ display_title: "All", audience_key: "all" })
        ]}
        />
        <IconButton sx={{ p: '10px' }} aria-label="close" onClick={handleSearchClose}>
          <Close data-testid="close-icon" />
        </IconButton>
      </Box>,
    </>
  );
};

export default SearchBar;
