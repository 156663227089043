/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require("css.escape");

export { onClientEntry } from './src/components/on-client-entry';
export { wrapRootElement } from './src/components/wrap-root-element';
