import { Reference, SimpleReference } from "../../models/app-data-model";
import { getContentHubDamAsset } from "../../../shared/utils/get-contenthub-dam-asset";

/**
 * This hook is meant to get the URL from a Reference array
 *
 * @param reference (as a Reference array)
 * @returns a URL (internal or external) as a string
 */
function useReferenceUrl(reference?: Reference[]): SimpleReference | undefined {

  let contentType =
    reference?.find((el) => el.internal?.type)?.internal?.type;
  const link = (reference?.find((el) => el?.url?.length)?.url) || (reference && getContentHubDamAsset(reference[0]?.contenthub_asset)?.url);

  if (contentType === "external_link" || contentType === "ph_external_link") {
    contentType = 'ph_external_link';
  }
  else
    contentType = 'ph_internal_link';

  const finalReference: SimpleReference = {
    href: link as string,
    type: <SimpleReference["type"]>contentType,
  }

  return finalReference;
}

export default useReferenceUrl;
