import { KendraApiResponse } from "../../hooks/use-kendra-api/useKendraApi";
import httpClient from "../http-client/http-client";

const KendraApiService = {
    getSearchResult(queryText: string) {
        const searchQueryParams = `?query=${queryText ? queryText : ''}`;
        const path = `${process.env.GATSBY_KENDRA_API_ENDPOINT}${searchQueryParams}`;
         const key = process.env.GATSBY_KENDRA_API_KEY;
        const headers = { 'x-api-key': key };

        return httpClient
            .get(path, headers)
            .then((response: any) => {
                return response.data as KendraApiResponse;
            })
            .catch((e) => {
                throw new Error(`Error when calling Kendra API: ${e}`);
            });
    }
};

export default KendraApiService;