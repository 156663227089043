import "./SearchSpellCorrection.scss";

import React, { useContext } from "react";

import {
  Box,
  Link,
  Typography
} from "@mui/material";

import { UiStateContext } from "../../shared/contexts/ui-state/UiStateContext";
import { SearchSettings } from "../../shared/models/app-data-model";
import RichTextBlock from "../../shared/components/rich-text-block/RichTextBlock";

export type SearchSpellCorrectionProps = {
  searchSettings: SearchSettings;
};

const SearchSpellCorrection: React.FC<SearchSpellCorrectionProps> = ({ searchSettings }) => {
  const uiStateContext = useContext(UiStateContext);

  const searchTermChange = () => {
    uiStateContext.setSearchQueryText?.(uiStateContext.searchSuggestedQueryText);
    uiStateContext.setSearchSuggestedQueryText?.('');
  };

  return <>
  <Box className='search-spell-correction'>
      <Typography variant='h3'>No results found for "{uiStateContext.searchQueryText}". Did you mean "
        <Link className='suggested-link'
        data-testid='suggested-text'
        onClick={searchTermChange}>{uiStateContext.searchSuggestedQueryText}</Link>"?</Typography>
      <RichTextBlock content={searchSettings.no_results_found_message} />
    </Box>
  </>;
};

export default SearchSpellCorrection;
