import {
  useCallback,
  useEffect,
  useState
} from "react";

import { PhSiteSettings } from "../../models/app-data-model";
import ContentStackApiService, { ContentTypes } from "../../services/contentstack-api/contentstack-api-service";

// eslint-disable-next-line
export type ContentStackContentResponse = {
  contentType: ContentTypes;
  entryUid?: string;
  content: PhSiteSettings;
};
export type ApiServiceStatus = { responses: ContentStackContentResponse[]; isRunning: boolean; hasError: boolean; error?: any };
export type SimpleContentQuery = { contentType: ContentTypes; entryUid?: string; referencedFields?: string[] };

/**
 * This is our ContentStack Api Hook, and it is capable of fetching ContentStack content at RUNTIME
 * (instead of at buildtime like our graphql)
 * @param queries an array of queries you'd like to fetch from ContentStack via RUNTIME http clients
 * @returns an array of responses, in the same order of your queries
 */
const useContentStack = (queries: SimpleContentQuery[]): ApiServiceStatus => {
  const [isRunning, setIsRunning] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(undefined as any);
  const [responses, setResponses] = useState(new Array<ContentStackContentResponse>());
  const [apiStatus, setApiStatus] = useState({ isRunning: false, responses: responses } as ApiServiceStatus);

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // empty deps ensures it only happens once per load

  useEffect(() => {
    setApiStatus({ isRunning: isRunning, hasError: hasError, responses: responses, error: error });
  }, [isRunning, hasError, responses, error]);

  const apiCall = useCallback(async () => {
    const results = new Array<ContentStackContentResponse>();

    for (let index = 0; index < queries.length; index++) {
      const query = queries[index];
      await ContentStackApiService.getContent(query.contentType, query.entryUid, query.referencedFields)
        .then(async (content) => {
          setIsRunning(false);
          results.push({ content: content, contentType: query.contentType, entryUid: query.entryUid });
        })
        .catch((err) => {
          setError(err);
          setHasError(true);
          setIsRunning(false);
        });
    }

    setResponses(results);
  }, [setIsRunning, setResponses, setHasError, setError, queries]);

  return apiStatus;
};

export default useContentStack;
