import { createMedia } from '@artsy/fresnel';

export const mediaBreakpoints = {
  xs: 0,
  sm: 600, // up to horizontal mobile
  md: 900, // up to tablet
  lg: 1200, // up to small desktop
  xl: 1536 // up to large desktop
};

const PhMedia = createMedia({
  breakpoints: mediaBreakpoints
});

// Make styles for injection into the header of the page
export const mediaStyles = PhMedia.createMediaStyle();

export const { Media, MediaContextProvider } = PhMedia;
