import React from "react";

import { MainNavigationItem, SearchFilterByAudience } from "../../models/app-data-model";

export type GlobalUiState = {
  subNavDropdownOpen: boolean;
  setSubNavDropdownOpen?: (subNavDropdownOpen: boolean) => void;
  dropDownData: MainNavigationItem;
  setDropDownData?: (dropDownData: MainNavigationItem) => void;
  subNavOpenIndex: number;
  setSubNavOpenIndex?: (index: number) => void;
  searchQueryText: string;
  setSearchQueryText?: (searchQueryText: string) => void;
  searchSuggestedQueryText: string;
  setSearchSuggestedQueryText?: (searchSuggestedQueryText: string) => void;
  searchOpen: boolean;
  setSearchOpen?: (searchOpen: boolean) => void;
  isSearchFilterPageOn: boolean;
  setIsSearchFilterPageOn?: (isSearchFilterPageOn: boolean) => void;
  isSearchFilterDocumentOn: boolean;
  setIsSearchFilterDocumentOn?: (isSearchFilterDocumentOn: boolean) => void;
  searchPage: number;
  setSearchPage?: (searchPage: number) => void;
  searchFilterByAudience: SearchFilterByAudience;
  setSearchFilterByAudience?: (searchFilterByAudience: SearchFilterByAudience) => void;
  loginMenuOpen: boolean;
  setLoginMenuOpen?: (loginMenuOpen: boolean) => void;
};

// instead of just using our AppDataModel as our full data context...
// let's wrap it in an object so it's flexible if we need to add things outside of our AppDataModel for application state
// eslint-disable-next-line max-len
const UiStateContext = React.createContext({
  subNavOpen: false,
  searchOpen: false,
  loginMenuOpen: false
} as unknown as GlobalUiState);

export { UiStateContext };
