import './TextWithHighlight.scss';
import React from 'react';
import { isNullOrUndefined, unionSortedHighlights } from '../../services/utils';
import { TextHighlight } from '../../models/app-data-model';
import HighlightedText from '../highlighted-text/HighlightedText';


export type TextWithHighlightProps = {
  className?: string;
  text: string;
  highlights: TextHighlight[];
};

const TextWithHighlight: React.FC<TextWithHighlightProps> = ({ text, highlights, className }) => {
  // istanbul ignore else
  if (highlights.length === 0) {
    return <span className={className}>{text}</span>;
  }
  
  // TopAnswer will be out of order, sort the highlights
  const sortedHighlights = unionSortedHighlights(
    highlights.sort(
      (highlight1: any, highlight2: any) =>
        highlight1.BeginOffset - highlight2.BeginOffset
    )
  );

  let lastHighlight;
  // istanbul ignore else
  if (sortedHighlights.length > 0) {
    lastHighlight = sortedHighlights[sortedHighlights.length - 1];
  }

  return <span className={className}>
    {sortedHighlights.map((highlight: any, idx: number) => (
      <span key={idx}>
        <span>
          {text.substring(
            idx === 0 ? 0 : sortedHighlights[idx - 1].EndOffset,
            highlight.BeginOffset
          )}
        </span>
        <HighlightedText text={text} highlight={highlight} />
      </span>
    ))}
    {lastHighlight && <span>{text.substring(lastHighlight.EndOffset)}</span>}
  </span>;
};

export default TextWithHighlight;
