import "./SearchFilterMobile.scss";

import React, {
  useCallback,
  useContext,
  useMemo
} from "react";

import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";

import { UiStateContext } from "../../shared/contexts/ui-state/UiStateContext";
import { AppDataContext } from "../../shared/contexts/app-data/AppDataContext";

const SearchFilterMobile: React.FC = () => {
  const uiStateContext = useContext(UiStateContext);
  const appDataContext = useContext(AppDataContext);
  const searchFilterByAudience = appDataContext?.appData?.globalSettings?.search_settings.search_filter_by_audience;

  const tabs = useMemo(
    () => [...[{ display_title: "All", audience_key: "all" }], ...searchFilterByAudience],
    [searchFilterByAudience]
  );

  const [isSearchFilterOpen, setIsSearchFilterOpen] = React.useState(false);
  const filterGroupLabel = "Filter results";
  const handleFilterByAudience = useCallback((event: { target: { value: any; }; }) => {
    const audienceKey = event.target.value;
    const tab = tabs.find(x => x.audience_key === audienceKey);

    /* istanbul ignore else */
    if (tab) {
      uiStateContext.setSearchFilterByAudience?.(tab);
    }

    uiStateContext.setSearchPage?.(1);
  }, [tabs, uiStateContext]);

  const handleSearchFilterClick = () => setIsSearchFilterOpen((isSearchFilterOpen) => !isSearchFilterOpen);

  const toggleDocumentFilter = (toggle: boolean) => {
    uiStateContext.setIsSearchFilterDocumentOn?.(toggle);
    uiStateContext.setSearchPage?.(1);
  };

  const togglePageFilter = (toggle: boolean) => {
    uiStateContext.setIsSearchFilterPageOn?.(toggle);
    uiStateContext.setSearchPage?.(1);
  };

  return <>
    <div className='search-filter-mobile-container'>

      <Accordion disableGutters expanded={isSearchFilterOpen} sx={{ zIndex: '9' }} className='search-filter-mobile'>
        <AccordionSummary
          expandIcon={<ExpandMore className='expand-icon' />}
          aria-controls='search-filter-menu'
          className='search-filter-header'
          onClick={handleSearchFilterClick}
          data-testid="search-filter-menu"
        >
          <Typography className='search-filter-title'>
            {filterGroupLabel}
          </Typography>
        </AccordionSummary>
        <div className='search-filter-inner-container'>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label" className='search-filter-header'>Refine by</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="all"
              name="radio-buttons-group"
              onChange={handleFilterByAudience}
            >
              <>
                {
                  tabs.map((tab, index) => {
                    return <FormControlLabel value={tab.audience_key} control={<Radio />} label={tab.display_title} key={index} data-testid={`audience-filter-item-${index}`}
                    />
                  })
                }
              </>
            </RadioGroup>
          </FormControl>
          <div>Filter By</div>
          <FormGroup >
            <FormControlLabel control={
              <Checkbox onChange={() => {
                toggleDocumentFilter(!uiStateContext.isSearchFilterDocumentOn)
              }}
                data-testid="documents" name="documents" checked={uiStateContext.isSearchFilterDocumentOn} />}
              label="Forms & Documents" />
            <FormControlLabel control={
              <Checkbox onChange={() => {
                togglePageFilter(!uiStateContext.isSearchFilterPageOn)
              }}
                data-testid="pages" name='pages' checked={uiStateContext.isSearchFilterPageOn} />} label="Pages" />
          </FormGroup>
        </div>
      </Accordion>
    </div>
  </>;
};

export default SearchFilterMobile;
