interface HasLength {
    readonly length: number;
}

export const isNullOrUndefined = (it: any) => it === null || it === undefined;

export const isNullOrEmpty = (it: HasLength) =>
  isNullOrUndefined(it) || it.length === 0;

export function unionSortedHighlights(highlights: any) {
  if (isNullOrEmpty(highlights)) {
    return highlights;
  }

  let prev = highlights[0];
  const unioned = [prev];
  for (let i = 1; i < highlights.length; i++) {
    const h = highlights[i];
    if (prev.EndOffset >= h.BeginOffset) {
      // union
      prev.EndOffset = Math.max(h.EndOffset, prev.EndOffset);
      prev.TopAnswer = prev.TopAnswer || h.TopAnswer;
    } else {
      // disjoint, add to results
      unioned.push(h);
      prev = h;
    }
  }

  return unioned;
}
