import React from "react";

import ReactHtmlParser from "react-html-parser";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@mui/material";

const ConfirmDialog = ({ open, title, content, onClose }) => {
  const handleClose = (result: boolean) => {
    onClose(result);
  };

  const message = ReactHtmlParser(content);

  return (
    <React.Fragment>
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)} color="primary" autoFocus>
          Continue
        </Button>        
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
    </React.Fragment>
  );
};

export default ConfirmDialog;