import React from 'react';
import { ThemeProvider } from '@mui/material';
import { lightTheme } from '@priorityhealth/canopy-react/src/mui/ph-mui-theme';

import UiStateContextProvider from "design-system/shared/contexts/ui-state/UiStateContextProvider";
import AppDataContextProvider from "design-system/shared/contexts/app-data/AppDataContextProvider";
import { MediaContextProvider } from "design-system/shared/contexts/media/MediaContext";

export const wrapRootElement = ({ element }) => {
  return (
    <UiStateContextProvider>
    <AppDataContextProvider>
    <MediaContextProvider>
    <ThemeProvider theme={lightTheme}>
      {element}
    </ThemeProvider>
    </MediaContextProvider>
    </AppDataContextProvider>
    </UiStateContextProvider>
  )
};
