import "./Search.scss";

import React, { useContext } from "react";

import { Box } from "@mui/material";

import { AppDataContext } from "../shared/contexts/app-data/AppDataContext";
import { Media } from "../shared/contexts/media/MediaContext";
import { UiStateContext } from "../shared/contexts/ui-state/UiStateContext";
import useFeatureToggle from "../shared/hooks/use-feature-toggle/useFeatureToggle";
import { FeatureToggle } from "../shared/models/app-data-model";
import SearchBar from "./search-bar/SearchBar";
import SearchNav from "./search-nav/SearchNav";
import SearchQuickLinks from "./search-quick-links/SearchQuickLinks";
import SearchResults from "./search-results/SearchResults";

export type SearchProps = {
  featureToggles: FeatureToggle[]; 
};

const Search: React.FC<SearchProps> = ({featureToggles}) => {
  const uiStateContext = useContext(UiStateContext);
  const appDataContext = useContext(AppDataContext);
  const searchSettings = appDataContext?.appData?.globalSettings?.search_settings;
  const isKendraSearchResultsEnabled = useFeatureToggle('search-results-from-kendra', featureToggles)

  return (
    <>
      <Box className='search'>
        <Box className="search-bar-container">
          <SearchBar />
        </Box>
        <Box>{(uiStateContext.searchQueryText === '' || uiStateContext.searchQueryText?.length <= 2) && <SearchQuickLinks featureToggles={featureToggles} searchSettings={searchSettings} />}</Box>
        <Media greaterThanOrEqual="md" className='desktop'>
          <Box className="search-nav">
            {(uiStateContext.searchQueryText !== '' && uiStateContext.searchQueryText?.length > 2) && <SearchNav searchFilterByAudience={searchSettings.search_filter_by_audience} />}
          </Box>
        </Media>
        <Box className="search-results-container">{ isKendraSearchResultsEnabled && <SearchResults featureToggles={featureToggles} searchSettings={searchSettings} />}</Box>        
      </Box>
    </>
  );
}; 

export default Search;
