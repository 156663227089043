/* eslint-disable max-len */

/* istanbul ignore file */
import { AppDataModel } from 'design-system/shared/models/app-data-model';
import React, { useState, ReactNode } from 'react';
import { AppDataContext } from './AppDataContext';


export type AppDataContextProviderProps = {
  children: ReactNode;
};

/**
 * Provides our Global Application Data State throughout our application!
 *
 * @param {AppDataContextProviderProps} { children }
 * @returns
 */
const AppDataContextProvider: React.FC<AppDataContextProviderProps> = ({ children }: AppDataContextProviderProps) => {
  const initAppData = {} as AppDataModel;
  const [appData, setAppData] = useState(initAppData);

  return (
    <AppDataContext.Provider value={{ appData, setAppData }}>
      {children}
    </AppDataContext.Provider>
  );
};

export default AppDataContextProvider;
