import "./SearchNoResults.scss";

import React, { useContext } from "react";

import {
  Box,
  Typography
} from "@mui/material";

import { UiStateContext } from "../../shared/contexts/ui-state/UiStateContext";
import { SearchSettings } from "../../shared/models/app-data-model";
import RichTextBlock from "../../shared/components/rich-text-block/RichTextBlock";

export type SearchNoResultsProps = {
  searchSettings: SearchSettings;
};

const SearchNoResults: React.FC<SearchNoResultsProps> = ({ searchSettings }) => {
  const uiStateContext = useContext(UiStateContext);

  return <>
    <Box className='search-no-results'>
      <Typography variant='h3'>No results found for "{uiStateContext.searchQueryText}"</Typography>
      <RichTextBlock content={searchSettings.no_results_found_message} />
    </Box>
  </>;
};

export default SearchNoResults;
