import "./SearchFilter.scss";

import React, { useContext } from "react";

import {
  Checkbox,
  FormControlLabel,
  FormGroup
} from "@mui/material";

import { UiStateContext } from "../../shared/contexts/ui-state/UiStateContext";

const SearchFilter: React.FC = () => {
  const uiStateContext = useContext(UiStateContext);
  const filterGroupLabel = "Filter";

  const toggleDocumentFilter = (toggle: boolean) => {
      uiStateContext.setIsSearchFilterDocumentOn?.(toggle);
      uiStateContext.setSearchPage?.(1);
    };

    const togglePageFilter = (toggle: boolean) => {
      uiStateContext.setIsSearchFilterPageOn?.(toggle);
      uiStateContext.setSearchPage?.(1);
    };

  return <><div className='search-filter'>
    {filterGroupLabel}
    <FormGroup >
      <FormControlLabel control={
        <Checkbox onChange={ () => {toggleDocumentFilter(!uiStateContext.isSearchFilterDocumentOn)}} data-testid="documents" name="documents" checked={uiStateContext.isSearchFilterDocumentOn} />} label="Forms & Documents" />
      <FormControlLabel control={
        <Checkbox onChange={() => {togglePageFilter(!uiStateContext.isSearchFilterPageOn)}} data-testid="pages" name='pages' checked={uiStateContext.isSearchFilterPageOn}/>} label="Pages" />
    </FormGroup>
     </div></>;
};

export default SearchFilter;
